
export const info = {

    miniBio: [
        {
            text: 'I am a full-stack software engineer 💻'
        },
        {
            text: 'Based in Cardiff 🌎'
        },
        {
            text: "Agile enthusiast 🤝"
        },
        {
            text: "Proficient in Python 🐍"
        },
    ],
    socials: [
        {
            link: "https://github.com/mjonjones-veygo",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/mjonjones",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
    ],
}
